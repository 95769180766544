import styled, { css } from 'styled-components';
import { colors } from './../../assets/variables';

const Button = styled.button`
  -webkit-transition: all 300ms;
  transition: all 300ms;
  font-size: 16px;
  color: #fff;
  background-color: #0b2641;
  font-family: inherit;
  line-height: 1.15;
  position: relative;
  padding: 16px 20px;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  &:hover {
    color: rgb(255, 255, 255);
    background-color: rgba(11, 38, 65, 0.8);
  }
  ${(props) => {
    if (props.theme === 'WHITE') {
      return whiteStyles;
    }
  }}
  ${(props) => {
    if (props.disabled) {
      return disabledStyles;
    }
  }}
`;
const whiteStyles = css`
  background-color: white;
  color: #64748b;
  border: 1px solid #e2e8f0;
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: white;
    border: 1px solid #0784ab;
    color: #0784ab;
  }
`;
const disabledStyles = css`
  background-color: ${colors.disabled};
  color: ${colors.darkGray};
  cursor: not-allowed;
  box-shadow: none;

  :hover,
  :focus {
    box-shadow: none;
    background-color: ${colors.disabled};
    color: ${colors.darkGray};
  }
`;
export default { Button };
