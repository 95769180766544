import { connect } from 'react-redux';
import { sendSpid } from '../../actions/checker';
import { AppState } from '../../interfaces';
import InputSubmit from './InputSubmit';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    checker: state.checkerReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendSpid: (data, onSuccess, onFailed) =>
      dispatch(sendSpid(data, onSuccess, onFailed)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputSubmit);
