/* eslint-disable no-prototype-builtins */
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { Products, User } from '../../../interfaces';
import Styled from './Checkout.style';
import {
  SearchPostCode,
  Button,
  Typography,
  Input,
  Select,
  Checkbox,
  ProductSummary,
} from '../../index';
import { Row, Col } from 'react-styled-flexboxgrid';
import store from 'store2';

const titleOptions = [
  {
    value: 'Mr',
    label: 'Mr',
  },
  {
    value: 'Mrs',
    label: 'Mrs',
  },
  {
    value: 'Miss',
    label: 'Miss',
  },
  {
    value: 'Ms',
    label: 'Ms',
  },
  {
    value: 'Dr',
    label: 'Dr',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const OrderSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required'),
  numberOfStaff: Yup.number()
    .integer()
    .positive('This must be a positive number'),
  numberOfVisitors: Yup.number()
    .integer()
    .positive('This must be a positive number'),
  operatingDays: Yup.number()
    .integer()
    .max(7, 'Max number is 7 or lower')
    .positive('This must be a positive number'),
  postcode: Yup.string()
    .matches(
      /^([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/,
      'Invalid Postcode'
    )
    .required('Postcode is required'),
  address: Yup.string().required('Address is required'),
  email: Yup.string().email('Invalid email').required('This field is required'),
  toc: Yup.boolean().oneOf(
    [true],
    'You need to accept our terms and conditions'
  ),
  phone: Yup.string()
    .required('This field is required')
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
      'Mobile number is not valid'
    ),
});

interface CheckoutProps {
  changePage: Function;
  basket: {} | undefined;
  products: Products;
  addressCheck: Function;
  persistDetails: Function;
  submitOrder: Function;
  getPostcode: Function;
  clearAddress: Function;
  user: User;
  preCheckout: Function;
}

const Checkout: React.FC<CheckoutProps> = (props) => {
  const {
    changePage,
    clearAddress,
    submitOrder,
    getPostcode,
    user,
    basket,
    persistDetails,
    preCheckout,
    products,
  } = props;
  const { addresses } = user;
  const addressError = _.get(user, 'addresses.status.error');

  const renderCheckoutError = () => {
    if (!props.products.products.data) {
      return null;
    }

    const { basket, user } = props;
    const { products } = props.products.products.data.entities;

    if (products && basket) {
      return Object.keys(basket).map((productID, index) => {
        const productCreditAudit = _.get(user, `creditAudit.${productID}`);
        const errorMessage = _.get(productCreditAudit, 'message');
        return (
          errorMessage && (
            <li id={`item-${productID}`} key={index}>
              {errorMessage}
            </li>
          )
        );
      });
    }
  };

  const renderAddressSelect = () => {
    const { data } = addresses;

    if (data === null) {
      return [];
    }

    return data.result.map((address) => {
      const { displayname } = data.entities.addresses[address];

      return {
        value: address.toString(),
        label: displayname,
      };
    });
  };
  const { creditAudit } = products;
  const orderBasket = _.compact(
    _.map(basket, (element: { total: number }, id) => {
      const quantity = _.get(creditAudit, `${id}.allowedQuantity`);
      if (creditAudit.hasOwnProperty(id)) {
        if (quantity === 0) {
          return;
        }
        if (quantity > 0) {
          return {
            id,
            quantity: quantity,
          };
        }
      }
      return {
        id,
        quantity: element.total,
      };
    })
  );

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          ...user.details,
          postcode: '',
        }}
        validationSchema={OrderSchema}
        onSubmit={(values, { setSubmitting }) => {
          const getUser = store('user');
          if (getUser.postcode) {
            values.postcode = getUser.postcode;
          }
          submitOrder(
            {
              products: orderBasket,
              ...values,
              spid: getUser.spid,
              waterClientSlug: getUser.provider_slug,
              address: parseInt(values.address, 10),
            },
            () => {
              setSubmitting(false);
              changePage('thankyou');
              clearAddress();
            },
            () => {
              setSubmitting(false);
            }
          );
        }}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          const disableFindAddress =
            !values.postcode ||
            Object.prototype.hasOwnProperty.call(errors, 'postcode');
          const loadingFindAddress = addresses.status.loading;
          const nothingFound =
            _.get(addresses, 'data.result', []).length === 0 &&
            addresses.status.done === true;

          const postcodeReplaced = values.postcode.replace(/\s+/g, '');
          const renderPostcodeError = () => {
            console.log(user);
            if (addressError) {
              return addressError;
            }
            if (nothingFound && touched.postcode) {
              return `${addressError}`;
            }
          };

          const saveAndHandleChange = (e) => {
            handleChange(e);
            persistDetails({
              ...values,
              [e.target.name]:
                e.target.type === 'checkbox'
                  ? e.target.checked
                  : e.target.value,
            });
          };

          const handleSelectAddress = (e) => {
            saveAndHandleChange(e);

            preCheckout({
              postcode: postcodeReplaced,
              address: parseInt(e.target.value, 10),
              products: [...orderBasket],
            });
          };

          const generateFullAddress = () => {
            if (addresses.data === null) {
              return;
            }

            const { displayname, town, filteredpostcode } =
              addresses.data.entities.addresses[values.address];
            return (
              <React.Fragment>
                {displayname}, {town}, {filteredpostcode}
              </React.Fragment>
            );
          };
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} sm={12} xs={12}>
                  <Styled.Card style={{ minHeight: '150px' }}>
                    <Row>
                      <Col md={12} sm={12} xs={12}>
                        <h3>Select your address</h3>
                      </Col>
                      <Col md={12} sm={12} xs={12}>
                        <SearchPostCode
                          clearAddress={clearAddress}
                          setFieldValue={setFieldValue}
                          handleChange={saveAndHandleChange}
                          handleBlur={handleBlur}
                          value={values.postcode}
                          name="postcode"
                          disabled={disableFindAddress}
                          loading={loadingFindAddress}
                          onSearch={getPostcode}
                          error={renderPostcodeError()}
                        />
                      </Col>
                      <Col md={12} sm={12} xs={12}>
                        {addresses.data && addresses.data.result.length > 0 && (
                          <Select
                            onChange={handleSelectAddress}
                            onBlur={handleBlur}
                            value={values.address}
                            placeholder={
                              loadingFindAddress
                                ? 'Fetching addresses...'
                                : 'Select your address'
                            }
                            disabled={
                              loadingFindAddress ||
                              addresses.data.result.length <= 0
                            }
                            name="address"
                            options={renderAddressSelect()}
                            error={
                              touched.address && errors.address
                                ? errors.address
                                : undefined
                            }
                          />
                        )}
                      </Col>
                      {values.address && (
                        <Col md={12} sm={12} xs={12}>
                          <Styled.FullAddressTitle>
                            Your full Address:
                            <Styled.FullAddressWrap>
                              {generateFullAddress()}
                            </Styled.FullAddressWrap>
                          </Styled.FullAddressTitle>
                        </Col>
                      )}
                      <Col md={12} sm={12} xs={12}>
                        <p style={{ fontSize: '14px' }}>
                          Orders placed are for delivery within the South West
                          Water region only.
                          <br />
                          Please{' '}
                          <a
                            target="_blank"
                            style={{ color: '#007bbf', fontWeight: 600 }}
                            rel="noopener noreferrer"
                            href="https://www.getwaterfit.co.uk/#/contact-us"
                          >
                            contact us
                          </a>{' '}
                          if there is an address query.
                        </p>
                      </Col>
                    </Row>
                  </Styled.Card>
                  <Styled.Card>
                    <Row>
                      <Col md={12} sm={12} xs={12}>
                        <h3>Your details</h3>
                      </Col>
                      <Col md={6} sm={6} xs={6}>
                        <Select
                          onChange={saveAndHandleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          name="title"
                          placeholder="Your title"
                          options={titleOptions}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12} xs={12}>
                        <Input
                          onChange={saveAndHandleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          type="text"
                          id="firstName"
                          name="firstName"
                          label="First name*"
                          placeholder="First name"
                          error={
                            touched.firstName && errors.firstName
                              ? errors.firstName
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        <Input
                          onChange={saveAndHandleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          type="text"
                          name="lastName"
                          id="lastName"
                          label="Last name*"
                          placeholder="Last name"
                          error={
                            touched.lastName && errors.lastName
                              ? errors.lastName
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        <Input
                          onChange={saveAndHandleChange}
                          onBlur={handleBlur}
                          value={values.numberOfStaff}
                          type="number"
                          name="numberOfStaff"
                          id="numberOfStaff"
                          label="Number of staff"
                          placeholder="Number of staff"
                          error={
                            touched.numberOfStaff && errors.numberOfStaff
                              ? errors.numberOfStaff
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        <Input
                          onChange={saveAndHandleChange}
                          onBlur={handleBlur}
                          value={values.numberOfVisitors}
                          type="number"
                          name="numberOfVisitors"
                          id="numberOfVisitors"
                          label="Number of visitors per week"
                          placeholder="Number of visitors per week"
                          error={
                            touched.numberOfVisitors && errors.numberOfVisitors
                              ? errors.numberOfVisitors
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        <Input
                          onChange={saveAndHandleChange}
                          onBlur={handleBlur}
                          value={values.operatingDays}
                          type="number"
                          name="operatingDays"
                          id="operatingDays"
                          label="Operating days per week"
                          placeholder="Operating days per week"
                          error={
                            touched.operatingDays && errors.operatingDays
                              ? errors.operatingDays
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        <Input
                          onChange={saveAndHandleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          type="text"
                          name="phone"
                          id="phone"
                          label="Contact number*"
                          placeholder="Contact number"
                          error={
                            touched.phone && errors.phone
                              ? errors.phone
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={12} sm={12} xs={12}>
                        <Input
                          onChange={saveAndHandleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          type="email"
                          name="email"
                          label="Email*"
                          id="email"
                          placeholder="Email address"
                          error={
                            touched.email && errors.email
                              ? errors.email
                              : undefined
                          }
                        />
                      </Col>

                      <Col md={12} sm={12} xs={12}>
                        <Checkbox
                          boxSize={20}
                          id="toc"
                          name="toc"
                          type="checkbox"
                          value={values.toc}
                          checked={values.toc}
                          onChange={saveAndHandleChange}
                          error={
                            errors.toc && touched.toc ? errors.toc : undefined
                          }
                        >
                          I agree to the{' '}
                          <a
                            target="_blank"
                            aria-label="Check out our terms and conditions"
                            rel="noopener noreferrer"
                            style={{ color: '#007bbf', fontWeight: 600 }}
                            href="https://savewatersavemoney.co.uk/terms"
                          >
                            Terms and Conditions
                          </a>{' '}
                          and{' '}
                          <a
                            target="_blank"
                            aria-label="Check out our privacy policy"
                            rel="noopener noreferrer"
                            style={{ color: '#007bbf', fontWeight: 600 }}
                            href="https://savewatersavemoney.co.uk/privacy-policy"
                          >
                            Privacy Policy
                          </a>
                          *
                        </Checkbox>
                      </Col>
                      <Col md={12} sm={12} xs={12}>
                        <p style={{ fontSize: '14px' }}>
                          <i>* - fields are required</i>
                        </p>
                      </Col>
                    </Row>
                  </Styled.Card>
                </Col>
                <Col md={6} sm={12} xs={12}>
                  <Styled.Card style={{ minHeight: '150px' }}>
                    <h3>Your Products</h3>
                    <p style={{ fontSize: '15px', marginBottom: '16px' }}>
                      If your business requires more product than what is
                      currently available please{' '}
                      <a
                        style={{
                          textDecoration: 'none',
                          fontWeight: 600,
                          color: '#007bbf',
                        }}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.getwaterfit.co.uk/#/contact-us"
                      >
                        contact us
                      </a>
                      .
                    </p>

                    <ProductSummary user={_.get(props, 'products')} />

                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        id="edit-my-basket"
                        onClick={(e) => {
                          e.preventDefault();
                          clearAddress();
                          changePage('products');
                        }}
                      >
                        Back to products
                      </Button>
                    </div>
                  </Styled.Card>
                </Col>
              </Row>

              {user.submission.status.error ? (
                <Row>
                  <Col md={12} sm={12} xs={12}>
                    <Styled.Error>
                      {user.submission.status.error && user.submission.data
                        ? user.submission.data
                        : null}
                      <div>{renderCheckoutError()}</div>
                    </Styled.Error>
                  </Col>
                </Row>
              ) : null}

              <Row>
                <Col
                  style={{ justifyContent: 'flex-end', display: 'flex' }}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Button
                    id="submit-order"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit order
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Styled.Wrap id="checkout-wrap">
      <Typography size="1" text="Checkout" />
      {renderForm()}
    </Styled.Wrap>
  );
};

export default Checkout;
