import ThankYou from './ThankYou';
import { connect } from 'react-redux';

import { AppState } from '../../../interfaces';

const mapStateToProps = (state: AppState) => ({
  order: state.orderReducer,
  user: state.userReducer,
});

export default connect(mapStateToProps, null)(ThankYou);
