import { connect } from 'react-redux';
import { reset } from '../../actions/freeProducts';
import { AppState } from '../../interfaces';
import Header from './Header';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    checker: state.checkerReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => dispatch(reset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
