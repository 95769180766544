import React from 'react';
import Styled from './Loader.style';
import Typography from '../Typography';
export const Loader: React.FC = () => {
  return (
    <Styled.Wrap>
      <Typography size="p" text="Please wait" style={{ marginBottom: '0px' }} />
      <Styled.Loader>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Styled.Loader>
    </Styled.Wrap>
  );
};

export default Loader;
