import ProductSummary from './ProductSummary';
import { connect } from 'react-redux';
import {
  deleteFromBasket,
  addToOrder,
  removeFromOrder,
  updateOrder,
  addressCheck,
} from '../../actions/freeProducts';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  products: state.productsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  deleteFromBasket: (id: number) => dispatch(deleteFromBasket(id)),
  addToOrder: (id: number) => dispatch(addToOrder(id)),
  removeFromOrder: (id: number) => dispatch(removeFromOrder(id)),
  updateOrder: (id: number) => dispatch(updateOrder(id)),
  addressCheck: (data) => dispatch(addressCheck(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSummary);
