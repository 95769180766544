import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colors } from '../../../assets/variables';
import { Button } from '../../index';
const cardStyle = css`
  position: relative;
  background-color: white;
  border: 4px solid #007bbf;
  border-radius: 10px;
  padding: 24px 32px;
  margin-bottom: 20px;
  @media screen and (max-width: 700px) {
    padding: 12px 24px;
  }
`;
const errorStyle = css`
  position: relative;
  background-color: #f65959;
  border: 4px solid #ff5151;
  border-radius: 10px;
  padding: 24px 32px;
  margin-bottom: 20px;
`;

const Wrap = styled.div`
  margin-bottom: 32px;
`;

const Card = styled.div`
  ${cardStyle}
`;

const Products = styled.div`
  flex: 1 1 400px;
`;

const UserDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  text-align: right;
`;

const FieldError = styled.div`
  font-size: 0.8em;
  color: ${colors.error};
  margin-top: 5px;
`;
const Postcode = styled.div`
  font-size: 20px;
  margin-bottom: 16px;
  b {
    color: ${colors.action};
    font-weight: 700;
  }
`;
const Input = styled.input`
  min-height: 50px;
  width: 90%;
  padding: 0 16px;
  border-radius: 10px;
  margin-bottom: 16px;
  border: 2px solid rgb(144, 144, 144);
`;
const List = styled.li`
  position: relative;
  margin-bottom: 10px;
  &:before {
    content: none; /* This is used by South East Water */
  }
`;

const ListQuantity = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  color: ${colors.action};
  font-weight: 600;
  ${(props) => {
    if (props.disabled) {
      return css`
        text-decoration: line-through;
        color: ${colors.disabled};
      `;
    }
  }};
`;
const ListText = styled.div`
  margin-left: 45px;
  ${(props) => {
    if (props.disabled) {
      return css`
        text-decoration: line-through;
        color: ${colors.disabled};
      `;
    }
  }};
`;

const ErrorMessage = styled(FieldError)`
  padding-left: 25px;
`;

const FullAddressTitle = styled.div`
  font-weight: bolder;
  margin-bottom: 5px;
`;

const FullAddressWrap = styled.span`
  margin-left: 10px;
  font-weight: lighter;
`;

const ProductsRemoved = styled.div`
  position: relative;
  background-color: ${transparentize(0.3, colors.error)};
  color: ${colors.baseLight};
  padding: 1em;
  margin-bottom: 1em;
`;

const Error = styled.div`
  ${errorStyle}
  display:flex;
  flex-direction: row;
  color: white;
`;
const CheckButton = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;
const AddressButton = styled(Button)`
  padding: 8px;
  font-size: 20px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;
export default {
  ProductsRemoved,
  FullAddressWrap,
  FullAddressTitle,
  List,
  ListText,
  ListQuantity,
  FieldError,
  ErrorMessage,
  Postcode,
  Wrap,
  Products,
  UserDetails,
  Card,
  Footer,
  Error,
  Input,
  CheckButton,
  AddressButton,
};
