import * as React from 'react';
import iconTimes from './icons/times';
import iconArrow from './icons/arrow';
import iconPlus from './icons/plus';
import iconMinus from './icons/minus';
import iconSearch from './icons/search';
import iconTrash from './icons/trash';
import iconRefresh from './icons/refresh';
export const allIcons = {
  times: iconTimes,
  arrow: iconArrow,
  plus: iconPlus,
  minus: iconMinus,
  search: iconSearch,
  trash: iconTrash,
  refresh: iconRefresh,
};

const IconRoot = (props) => {
  const { icon } = props;
  // eslint-disable-next-line no-prototype-builtins
  const iconExists = allIcons.hasOwnProperty(icon);
  if (iconExists) {
    const SpecificIcon = allIcons[icon];
    return <SpecificIcon {...props} />;
  }
  const FallbackIcon = allIcons.times;
  return <FallbackIcon {...props} />;
};

export default IconRoot;
