import styled from 'styled-components';

const Wrapper = styled.div`
  height: auto;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  input {
    min-height: 46px;
    padding: 0 16px;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
  }
  label {
    font-weight: 600;
    font-size: 14px;
  }
  div {
    color: red;
    font-size: 14px;
    padding: 4px 10px;
  }
`;

export default { Wrapper };
