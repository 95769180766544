import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Icon } from '../index';
import Styled from './InputSubmit.style';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useNavigate, useLocation } from 'react-router-dom';
import * as _ from 'lodash';

interface InputSubmitProps {
  sendSpid: Function;
  checker: any;
}
const PostcodeSchema = Yup.object().shape({
  spid: Yup.string().required('Required'),
});

const PostcodeForm: React.FC<InputSubmitProps> = (props) => {
  const { sendSpid, checker } = props;
  const isAuth = localStorage.getItem('user');
  const error = _.get(checker, 'checkSpid.status.error');
  const loading = _.get(checker, 'checkSpid.status.loading');
  const done = _.get(checker, 'checkSpid.status.done');
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname;

  const correctPath = path.endsWith('/') ? path.slice(0, -1) : path;

  const getUrl = `${correctPath}/free-products`;
  React.useEffect(() => {
    if (done && isAuth) {
      navigate(`${getUrl}`);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        spid: '',
      }}
      validationSchema={PostcodeSchema}
      onSubmit={(values, { setSubmitting }) => {
        sendSpid(
          values,
          () => {
            setSubmitting(false);
            navigate(`${getUrl}`);
          },
          () => {
            setSubmitting(false);
            console.log('failed');
          }
        );
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form id="postcodeForm" onSubmit={handleSubmit}>
          <Row>
            <Col sm={12} xs={12} md={8} style={{ display: 'flex' }}>
              <Styled.Input
                type="text"
                name="spid"
                id="spid"
                aria-label="Type your SPID here"
                placeholder="Enter your SPID here"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.spid}
              />
              <Styled.ButtonInput
                id={'postcodeSubmit'}
                loaderColor="#062640"
                aria-label="Submit postcode"
                loading={`${isSubmitting}` || loading}
                type="submit"
              >
                <Icon
                  colorPrimary="#fff"
                  colorSecondary="#fff"
                  icon="arrow"
                  size={20}
                />
              </Styled.ButtonInput>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={12} md={8}>
              {error ? <Styled.Error>{error}</Styled.Error> : null}
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              xs={12}
              md={8}
              style={{ marginTop: '8px', fontSize: '14px' }}
            >
              <i>
                *Check your bill for the Supply Pipe Identification Number
                (SPID) or contact your water retailer.
              </i>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default PostcodeForm;
