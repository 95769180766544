import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';
import { transparentize } from 'polished';

const List = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #bcbcbc;
  &:last-child {
    border: 0;
  }
  &:before {
    content: none; /* This is used by South East Water */
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;
`;
const ListQuantity = styled.div`
  color: ${colors.action};
  font-weight: 600;
  display: flex;
  margin: 0 16px;
  ${(props) => {
    if (props.disabled) {
      return css`
        text-decoration: line-through;
        color: ${colors.disabled};
      `;
    }
  }};
`;

const ListText = styled.div`
  min-height: 43px;
  flex: 1;
  display: flex;
  align-items: center;
  ${(props) => {
    if (props.disabled) {
      return css`
        text-decoration: line-through;
        color: ${colors.disabled};
      `;
    }
  }};
`;
const ErrorMessage = styled.div`
  padding-right: 48px;
  font-size: 0.8em;
  font-weight: 600;
  color: #d8752e;
`;
const Delete = styled.div`
  width: 28px;
  height: 28px;
  background-color: ${colors.error};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${transparentize(0.3, colors.error)};
    transition: all 200ms ease-in-out;
  }
`;

const CounterText = styled.div`
  font-size: 22px;
  width: auto;
  min-width: 41px;
  margin: 0 4px;
  text-align: center;
  font-weight: 600;
`;
const Btn = styled.button`
  padding: 6px;
  width: 34px;
  height: 34px;
  min-width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: 0.3s;
  border: 2px solid #d7f1ff;
  &:hover {
    background-color: ${(props) => (props.disabled ? '#ffff' : '#d7f1ff')};
    transition: 0.3s;
  }
  & svg {
    path {
      fill: ${colors.primary};
    }
  }
  & div {
    width: 22px;
    height: 22px;
    min-width: 22px;
    border-radius: 50%;
    background-color: ${colors.action};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const ErrorWrap = styled.div`
  display: flex;
  margin-top: 16px;
  border-radius: 6px;
  align-items: center;
`;
const ErrorUpdate = styled.div`
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  background: ${colors.action};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const NotAllowed = styled.span`
  color: ${colors.darkGray};
  text-decoration: line-through;
  margin-right: 8px;
`;
const Wrap = styled.div``;
export default {
  List,
  CounterText,
  ErrorMessage,
  ListText,
  ListQuantity,
  Delete,
  Btn,
  Item,
  ErrorWrap,
  ErrorUpdate,
  NotAllowed,
  Wrap,
};
