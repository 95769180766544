import React from 'react';
import { Footer, Header, Typography, InputSubmit } from '../../index';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Styled from './ClientLanding.style';

const ClientLanding: React.FC = () => {
  return (
    <Styled.Wrapper>
      <Header />
      <Styled.Wrap>
        <Grid>
          <Row>
            <Col md={7} sm={12} xs={12}>
              <Typography
                size="1"
                text="Free water saving devices for your business, in partnership with your water retailer and South West Water."
              />
              <Typography
                size="p"
                style={{ marginBottom: '32px' }}
                text="Enter your business Supply Pipe Identification Number (SPID)* in the box below to see the free water saving devices your business can get."
              />

              <InputSubmit />
            </Col>
          </Row>
        </Grid>
      </Styled.Wrap>
      <Footer />
    </Styled.Wrapper>
  );
};

export default ClientLanding;
