const initialState = {
  appWidth: 0,
  activePage: 'products',
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET':
      return {
        ...state,
        activePage: 'products',
      };
    case 'SET_COMPONENT_WIDTH':
      return {
        ...state,
        appWidth: action.appWidth,
      };

    case 'CHANGE_PAGE':
      return {
        ...state,
        activePage: action.activePage,
      };

    default:
      return {
        ...state,
      };
  }
};

export default uiReducer;
