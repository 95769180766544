import FreeProducts from './FreeProducts';
import { connect } from 'react-redux';
import {
  addToOrder,
  removeFromOrder,
  changePage,
  getPostcode,
  clearAddress,
  reset,
  addressCheck,
  persistDetails,
  getWaterClient,
  submitOrder,
} from '../../../actions/freeProducts';
import { AppState } from '../../../interfaces';

const mapStateToProps = (state: AppState) => ({
  products: state.productsReducer,
  ui: state.uiReducer,
  user: state.userReducer,
  order: state.orderReducer,
});

const mapDispatchToProps = (dispatch) => ({
  persistDetails: (details) => dispatch(persistDetails(details)),
  submitOrder: (data, onSuccess, onFailed) =>
    dispatch(submitOrder(data, onSuccess, onFailed)),
  addressCheck: (data) => dispatch(addressCheck(data)),
  reset: () => dispatch(reset()),
  clearAddress: () => dispatch(clearAddress()),
  changePage: (activePage) => dispatch(changePage(activePage)),
  getPostcode: (postCode) => dispatch(getPostcode(postCode)),
  addToOrder: (id: number) => dispatch(addToOrder(id)),
  removeFromOrder: (id: number) => dispatch(removeFromOrder(id)),
  getWaterClient: (slug) => dispatch(getWaterClient(slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeProducts);
