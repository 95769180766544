import React from 'react';
import { Header, Footer, Main } from '../index';
import Styled from './DefaultLayout.style';

export const DefaultLayout: React.FC = () => {
  return (
    <Styled.Wrap>
      <Header />
      <Main />
      <Footer />
    </Styled.Wrap>
  );
};

export default DefaultLayout;
