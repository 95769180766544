import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Wrap = styled.div`
  background: #d7f1ff;
  padding: 16px 0;
  flex: 1;
  padding: 60px;
  @media screen and (max-width: 600px) {
    padding: 32px 0px;
  }
`;

export default { Wrapper, Wrap };
