import React from 'react';
import { Icon } from '../index';
import Styled from './SearchPostcode.style';
import { Button } from '../index';

interface SearchPostCodeProps {
  handleChange: Function;
  handleBlur: Function;
  clearAddress: Function;
  setFieldValue: Function;
  value: number | string;
  name: string;
  placeholder?: string;
  disabled: boolean;
  onSearch: Function;
  loading: boolean;
  error?: string | boolean;
}

const SearchPostCode: React.FC<SearchPostCodeProps> = (props) => {
  const {
    handleChange,
    handleBlur,
    value,
    disabled,
    onSearch,
    loading,
    setFieldValue,
    clearAddress,
    error,
  } = props;

  return (
    <>
      <Styled.InputGroup>
        <Styled.InputSearch
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          disabled={loading}
          onKeyDown={(e) => {
            setFieldValue('address', '');
            clearAddress();
            if (e.key === 'Enter') {
              e.preventDefault();
              if (!disabled && !loading) {
                e.target.blur();
                onSearch(value);
                setFieldValue('address', '');
              }
            }
          }}
          name="postcode"
          label="Your postcode"
          placeholder="Your postcode"
          error={error}
        />
        <Button
          disabled={disabled || loading}
          style={{ minWidth: '64px' }}
          onClick={() => {
            onSearch(value);
            setFieldValue('address', '');
          }}
          type="button"
          id="search-postcode"
        >
          <Icon colorPrimary="#fff" size={30} icon="search" />
        </Button>
      </Styled.InputGroup>
      <p style={{ color: '#FF4136', fontSize: '14px', fontWeight: 600 }}>
        {error}
      </p>
    </>
  );
};

export default SearchPostCode;
