import * as React from 'react';

const refresh = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(9.500000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <polyline points="81 0 81 27 54 27"></polyline>
        <path d="M0,45.00045 C0.00711,37.1853 2.2752,29.539305 6.530625,22.98438 C10.786095,16.4295 16.84719,11.245815 23.982795,8.058555 C31.1184,4.87134 39.02355,3.816675 46.74555,5.02182 C54.4671,6.226965 61.6752,9.64044 67.5,14.850495 L81,27.000495"></path>
        <polyline points="0 90 0 63 27 63"></polyline>
        <path d="M81,45 C80.9928,52.81515 78.7248,60.4611 74.46915,67.01625 C70.21395,73.57095 64.1529,78.7545 57.01725,81.94185 C49.8816,85.1292 41.97645,86.184 34.25445,84.97845 C26.533035,83.77335 19.325025,80.3601 13.5,75.15 L0,63"></path>
      </g>
    </g>
  );
};
export default refresh;
