import * as _ from 'lodash';
import { CheckerReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import store from 'store2';

export const initialState: CheckerReducer = {
  waterClients: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  checkSpid: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const checkerReducer = createReducer(initialState, {
  SPID_CHECK_FULFILLED: (state, action) => {
    state.checkSpid.status = {
      loading: false,
      error: false,
      done: true,
    };
    store('user', action.payload.data.data);
    state.checkSpid.data = _.get(action, 'payload.data.data', null);
  },
  SPID_CHECK_PENDING: (state) => {
    state.checkSpid.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  SPID_CHECK_REJECTED: (state, action) => {
    state.checkSpid.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.errors'),
      done: false,
    };
  },
  GET_WATER_CLIENTS_FULFILLED: (state, action) => {
    state.waterClients.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.waterClients.data = _.get(action, 'payload.data.clients', null);
  },
  GET_WATER_CLIENTS_PENDING: (state) => {
    state.waterClients.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_WATER_CLIENTS_REJECTED: (state, action) => {
    state.waterClients.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});

export default checkerReducer;
