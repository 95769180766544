import { getVariable } from '../helpers/helpers';

export const colors = {
  baseDark: getVariable('colors', 'baseDark', '#575757'),
  baseLight: getVariable('colors', 'baseLight', '#FFF'),
  error: getVariable('colors', 'error', '#FF4136'),
  success: getVariable('colors', 'success', '#56BB00'),
  disabled: getVariable('colors', 'disabled', '#D8D8D8'),
  primary: getVariable('colors', 'primary', '#0b2641'),
  secondary: getVariable('colors', 'secondary', '#83389E'),
  action: getVariable('colors', 'action', '#25b3eb'),
  lightGray: getVariable('colors', 'lightGray', '#E0E0E0'),
  darkGray: getVariable('colors', 'darkGray', '#595959'),
  water: getVariable('colors', 'water', '#25b3eb'),
  energy: getVariable('colors', 'energy', '#F4AC05'),
  money: getVariable('colors', 'money', '#54A93E'),
  tertiary: getVariable('colors', 'action', '#d7f1ff'),
};
