import styled from 'styled-components';
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
`;
const Main = styled.div`
  flex: 1;
`;
export default { Wrap, Main };
