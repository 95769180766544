import axios from 'axios';
import { getV8MicroServiceUrl } from '../helpers/helpers';

const submitOrder = (data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'SUBMIT_ORDER',
      payload: axios.post(
        `${getV8MicroServiceUrl('business-freepack')}freepack/checkout`,
        data
      ),
    });

    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

const persistDetails = (details) => ({
  type: 'PERSIST_DETAILS',
  details,
});

const getWaterClient = (slug) => ({
  type: 'GET_CLIENT',
  payload: axios.get(
    `${getV8MicroServiceUrl('business-freepack')}freepack/products/${slug}`
  ),
});

//BE is not returning a proper error for this: TODO: add proper error handling
const getPostcodeFailed = () => ({
  type: 'GET_ADDRESSES_REJECTED',
});

const getPostcode = (postCode) => {
  return (dispatch) => {
    const postcodeReplaced = postCode.replace(/\s+/g, '');
    const response = dispatch({
      type: 'GET_ADDRESSES',
      payload: axios.get(
        `${getV8MicroServiceUrl(
          'business-freepack'
        )}freepack/address/${postcodeReplaced}/southwest-business`
      ),
      meta: {
        postCode,
      },
    });

    response.catch((err) => {
      dispatch(getPostcodeFailed());
      console.log(err);
    });
  };
};

const addressCheck = (data) => {
  return {
    type: 'ADDRESS_CHECK',
    payload: axios.post(
      `${getV8MicroServiceUrl('business-freepack')}freepack/pre-checkout`,
      data
    ),
  };
};

const clearAddress = () => ({
  type: 'CLEAR_ADDRESS',
});

const reset = () => ({
  type: 'RESET',
});

const addToOrder = (id) => ({
  type: 'ADD_TO_ORDER',
  id,
});

const removeFromOrder = (id) => ({
  type: 'REMOVE_FROM_ORDER',
  id,
});

const deleteFromBasket = (id) => ({
  type: 'DELETE_FROM_ORDER',
  id,
});
const updateOrder = (id) => ({
  type: 'UPDATE_ORDER',
  id,
});

const changePage = (activePage) => ({
  type: 'CHANGE_PAGE',
  activePage,
});

export {
  submitOrder,
  getPostcode,
  addToOrder,
  removeFromOrder,
  updateOrder,
  deleteFromBasket,
  clearAddress,
  changePage,
  reset,
  addressCheck,
  persistDetails,
  getWaterClient,
};
