import React from 'react';
import { Typography } from '../../index';
import { Row, Col } from 'react-styled-flexboxgrid';
import Styled from './ThankYou.style';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import store from 'store2';
interface ThankYouProps {
  order: any;
  changePage: Function;
  reset: Function;
  user: any;
}

const ThankYou: React.FC<ThankYouProps> = (props) => {
  const { user } = props;
  const orderId = _.get(user, 'submission.data.orderId');
  const navigate = useNavigate();
  const settings = store('user');
  const waterClient = settings.client_slug;
  const [seconds, setSeconds] = React.useState(8);
  const redirectText = `You will be redirected in ${seconds} seconds`;
  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      localStorage.removeItem('user');
      navigate(`/${waterClient}`);
      props.reset();
    }
  });

  const renderBasicPage = () => {
    return (
      <Styled.Box>
        <Styled.Img
          src="https://cdn.getwaterfit.co.uk/static/files/images/develop/component/thank-you.webp"
          alt="thanks"
        />
        <Styled.TextWrap>
          <Typography
            size="3"
            text="You will receive an email shortly to confirm the details of your order."
            style={{ marginBottom: '16px' }}
          />

          <Styled.OrderBox>
            <Typography
              size="p"
              style={{
                fontSize: '12px',
                color: 'white',
                marginBottom: '6px',
                marginTop: '0',
              }}
              text="Order number"
            />
            <Typography
              size="p"
              style={{
                fontSize: '48px',
                color: 'white',
                fontWeight: '700',
                margin: '0',
                lineHeight: 'normal',
              }}
              text={orderId}
            />
          </Styled.OrderBox>
        </Styled.TextWrap>
      </Styled.Box>
    );
  };

  return (
    <Row>
      <Col md={12} sm={12} xs={12}>
        <Typography size="1" text="Your order confirmation" />
      </Col>
      <Col md={12} sm={12} xs={12}>
        <Typography
          size="2"
          text="Thank you for placing your order for free water saving devices for your business."
          style={{ lineHeight: '24px' }}
        />
      </Col>
      <Col md={12} sm={12} xs={12}>
        <Typography
          size="4"
          text={redirectText}
          style={{ marginBottom: '16px' }}
        />
      </Col>
      <Col md={12} sm={12} xs={12}>
        {renderBasicPage()}
      </Col>
    </Row>
  );
};

export default ThankYou;
