import styled, { css, createGlobalStyle } from 'styled-components';
import { colors } from '../../assets/variables';

const GlobalStyle = createGlobalStyle`
select::-ms-expand {
    display: none;
}
`;

const resetForms = css`
  font-family: inherit; /* 1 */
  font-size: 14px; /* 1 */
  line-height: 1.2; /* 1 */
  margin: 0; /* 2 */

  ::placeholder {
    color: rgba(11, 38, 65, 0.7);
  }
`;

const Wrap = styled.div`
  position: relative;
  min-height: 80px;
  width: 100%;
`;

const CloseButon = styled.span`
  position: absolute;
  right: 25px;
  top: 8px;
  padding: 5px;
  cursor: pointer;
`;

const Select = styled.select`
  ${resetForms}
  width: 100%;
  height: 50px;
  transition: all 300ms;
  border: none;
  background-image: none;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  vertical-align: middle;
  padding: 0 16px;
  outline: none;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02Ljk5OTkzIDYuMjk2OTVMMS44NDg3NyAxLjE0NTc5QzEuNjU0NTcgMC45NTE1OTEgMS4zMzk4NSAwLjk1MTU5MSAxLjE0NTY1IDEuMTQ1NzlDMC45NTE0NSAxLjMzOTk5IDAuOTUxNDUgMS42NTQ3MSAxLjE0NTY1IDEuODQ4OTFMNi45OTk5MyA3LjcwMzE5TDEyLjg1NDMgMS44NDg3N0MxMy4wNDg1IDEuNjU0NTcgMTMuMDQ4NiAxLjMzOTg1IDEyLjg1NDQgMS4xNDU2NUMxMi42NjAxIDAuOTUxNDUgMTIuMzQ1NCAwLjk1MTQ1IDEyLjE1MTIgMS4xNDU2NUw2Ljk5OTkzIDYuMjk2OTVaIiBzdHJva2U9IiMwQjI2NDEiLz4KPC9zdmc+Cg==');
  background-position: calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  transition: 0.2s;

  ${(props) => {
    if (props.value === '') {
      return css`
        color: rgba(11, 38, 65, 0.7);
      `;
    }
  }}
`;

const FieldError = styled.div`
  font-size: 0.8em;
  color: ${colors.error};
  margin-top: 5px;
`;

export default {
  Wrap,
  Select,
  CloseButon,
  GlobalStyle,
  FieldError,
};
