import React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Styled from './Footer.style';
import { useLocation } from 'react-router-dom';

export const Footer: React.FC = () => {
  const location = useLocation();
  const path = location.pathname.substring(1);
  const date = new Date().getFullYear();
  return (
    <Styled.Wrap>
      <Grid>
        <Row>
          <Col md={12} sm={12} xs={12}>
            <a
              style={{
                marginRight: '16px',

                color: '#0b2641',
              }}
              href="https://savewatersavemoney.co.uk/cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie policy
            </a>
            <a
              href="https://savewatersavemoney.co.uk/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginRight: '16px',

                color: '#0b2641',
              }}
            >
              Privacy policy
            </a>
            <a
              href="https://savewatersavemoney.co.uk/terms"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginRight: '16px',

                color: '#0b2641',
              }}
            >
              Terms and conditions
            </a>
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12} md={8}>
            <p>
              Copyright © {date} Save Water Save Money Ltd. All rights reserved.
              Patent Pending.{' '}
              {path === 'southwest' || path === '' ? null : (
                <a
                  style={{
                    textDecoration: 'none',
                    fontWeight: 600,
                    color: '#007bbf',
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.getwaterfit.co.uk/#/contact-us"
                >
                  Contact us.
                </a>
              )}
            </p>
          </Col>
          <Col sm={12} xs={12} md={4} className="footer-logo">
            <img
              src="https://cdn.getwaterfit.co.uk/static/files/logos/swsm-color.svg"
              alt="SWSM Logo"
              height="50px"
            />
          </Col>
        </Row>
      </Grid>
    </Styled.Wrap>
  );
};

export default Footer;
