import * as React from 'react';
import * as _ from 'lodash';
import Styled from './Select.style';

interface SelectProps {
  [anyProp: string]: any;
  options: Array<{
    value: string;
    label: string;
  }>;
  placeholder?: string;
  onChange: Function;
  canClose?: boolean;
  value: string | undefined;
  error?: string;
  borderColor?: string;
  required?: boolean;
}

export const Select: React.FC<SelectProps> = props => {
  const {
    placeholder,
    canClose,
    options,
    onChange,
    error,
    value,
    borderColor,
    required,
    ...rest
  } = props;

  const renderList = () => _.map(options, el => (
    <option key={el.value} value={el.value}>
      {el.label}
    </option>
  ));

  return (
    <Styled.Wrap>
      <Styled.GlobalStyle />
      <Styled.Select
        value={value}
        style={borderColor ? { borderColor } : null}
        {...rest}
        onChange={e => {
          onChange(e);
        }}
      >
        {placeholder ? (
          <option value="" disabled>
            {placeholder}
            {required ? '*' : null}
          </option>
        ) : null}

        {renderList()}
      </Styled.Select>
      {canClose && value !== '' ? (
        <Styled.CloseButon
          onClick={() => {
            onChange('');
          }}
        >
          x
        </Styled.CloseButon>
      ) : null}
      {error ? <Styled.FieldError>{error}</Styled.FieldError> : null}
    </Styled.Wrap>
  );
};

export default Select;
