import { connect } from 'react-redux';
import { getWaterClients } from '../../../actions/checker';
import { AppState } from '../../../interfaces';
import Landing from './Landing';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    checker: state.checkerReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWaterClients: (data) => dispatch(getWaterClients(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
