/* eslint-disable react/jsx-key */
/* eslint-disable indent */
import React from 'react';
import * as _ from 'lodash';
import Styled from './ProductSummar.style';
import { Icon, Loader } from '../index';

interface ProductSummaryProps {
  products: any;
  user: any;
  deleteFromBasket: Function;
  removeFromOrder: Function;
  addToOrder: Function;
  disabled?: boolean;
}

const ProductSummary: React.FC<ProductSummaryProps> = (props) => {
  const { user, products, deleteFromBasket } = props;

  const getProducts = _.get(products, 'products.data.entities.products');
  const basket = _.get(products, 'products.basket');
  const loading = _.get(user, 'addressCheck.status.loading');
  if (loading) {
    return <Loader />;
  }
  const renderSummary = () => {
    if (!products.products.data) {
      return null;
    }

    if (getProducts && basket) {
      return Object.keys(basket).map((productID, index) => {
        const productCreditAudit = _.get(user, `creditAudit.${productID}`);
        const allowedQuantity = _.get(productCreditAudit, 'allowedQuantity');
        const errorMessage = _.get(productCreditAudit, 'message');
        const inOrder = basket && basket[productID].total;
        return (
          <Styled.List id={`item-${productID}`} key={index}>
            <Styled.Item>
              <Styled.ListText
                data-test="name"
                style={
                  allowedQuantity === 0
                    ? { textDecoration: 'line-through' }
                    : null
                }
              >
                {getProducts[productID].title}
              </Styled.ListText>
              <Styled.ListQuantity data-test="quantity">
                {allowedQuantity === 0 ? (
                  <Styled.CounterText>
                    <Styled.NotAllowed>{inOrder || 0}</Styled.NotAllowed>
                  </Styled.CounterText>
                ) : null}

                <Styled.CounterText>
                  {allowedQuantity >= 0 ? allowedQuantity : inOrder}
                </Styled.CounterText>
              </Styled.ListQuantity>
              <div style={{ justifyContent: 'flex-end' }}>
                <Styled.Delete
                  onClick={() => deleteFromBasket(parseInt(productID))}
                >
                  <Icon icon="trash" size={16} colorPrimary="#fff" />
                </Styled.Delete>
              </div>
            </Styled.Item>

            {errorMessage && (
              <Styled.ErrorWrap>
                <Styled.ErrorMessage data-test="error">
                  {errorMessage}.
                </Styled.ErrorMessage>
              </Styled.ErrorWrap>
            )}
          </Styled.List>
        );
      });
    }
  };

  return <Styled.Wrap>{renderSummary()}</Styled.Wrap>;
};

export default ProductSummary;
