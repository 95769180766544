import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';

const Product = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  line-height: 1.2em;
  border: 4px solid #007bbf;
  // box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 6px 0px;
  ${(props) => {
    if (props.appWidth > 800) {
      return desktopStyles;
    }
  }}
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrap = styled.div`
  display: flex;
  position: relative;
  width: 175px;
  min-width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const CounterText = styled.div`
  font-size: 24px;
  width: auto;
  min-width: 52px;
  text-align: center;
  font-weight: 600;
`;

const ContentWrap = styled.div`
  padding: 0 16px;
  flex-grow: 1;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  font-weight: 900;
  height: 67px;
  // margin: auto;
  text-align: center;
  min-height: 45px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ShortDescription = styled.div`
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
`;

const DefaultWrap = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border: 4px solid #007bbf;
  border-radius: 10px;
`;

const desktopStyles = css`
  flex-direction: column;
  ${ImageWrap} {
    margin: 0;
  }
  ${Title} {
    text-align: left;
  }
  ${ShortDescription} {
    text-align: left;
    margin-bottom: 0;
    padding-right: 20px;
  }
`;

const Btn = styled.button`
  padding: 6px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: 0.3s;
  border: 2px solid #d7f1ff;
  &:hover {
    background-color: ${(props) => (props.disabled ? '#ffff' : '#d7f1ff')};
    transition: 0.3s;
  }
  & svg {
    path {
      fill: ${colors.primary};
    }
  }
  & div {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${colors.action};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default {
  Btn,
  ButtonWrap,
  Product,
  Image,
  ImageWrap,
  ContentWrap,
  Title,
  ShortDescription,
  CounterText,
  DefaultWrap,
};
