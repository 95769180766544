import * as _ from 'lodash';
import { OrderReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: OrderReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const orderReducer = createReducer(initialState, {
  SUBMIT_ORDER_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = _.get(action, 'payload.data.data.items', null);
  },
  SUBMIT_ORDER_PENDING: (state) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  SUBMIT_ORDER_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});

export default orderReducer;
