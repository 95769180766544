import React from 'react';
import { ProductList, Loader } from '../../index';
import { Checkout, ThankYou } from '../index';
import { Ui } from '../../../interfaces';
import store from 'store2';
import * as _ from 'lodash';

interface FreeProductProps {
  addressCheck: Function;
  persistDetails: Function;
  products: any;
  user: any;
  order: any;
  addToOrder: Function;
  getPostcode: Function;
  changePage: Function;
  removeFromOrder: Function;
  reset: Function;
  clearAddress: Function;

  ui: Ui;
  getWaterClient: any;
  submitOrder: Function;
}

const FreeProducts: React.FC<FreeProductProps> = (props) => {
  const user = store('user');
  React.useEffect(() => {
    if (user.provider_slug) {
      props.getWaterClient(user.provider_slug);
    }
  }, []);

  const { error, loading } = props.products.products.status;

  if (error) {
    return (
      <div
        style={{
          marginTop: '40px',
          width: '100%',
          background: '#eee',
          borderRadius: '10px',
          padding: '16px',
          textAlign: 'center',
          fontWeight: 600,
        }}
      >
        <p>{error}</p>
      </div>
    );
  }
  if (loading) {
    return <Loader />;
  }
  const changePage = (page) => {
    props.changePage(page);
  };

  const renderPage = (name) => {
    const {
      removeFromOrder,
      addToOrder,
      getPostcode,
      user,
      clearAddress,
      submitOrder,
      reset,
      addressCheck,
      persistDetails,
      order,
      products,
    } = props;
    const { basket } = _.get(products, 'products');
    switch (name) {
      case 'checkout':
        return (
          <Checkout
            persistDetails={persistDetails}
            addressCheck={addressCheck}
            submitOrder={submitOrder}
            getPostcode={getPostcode}
            products={products}
            basket={basket}
            changePage={changePage}
            user={user}
            clearAddress={clearAddress}
            preCheckout={addressCheck}
          />
        );
      case 'thankyou':
        return (
          <ThankYou
            order={order}
            reset={reset}
            user={user}
            changePage={changePage}
          />
        );
      default:
        return (
          <ProductList
            addToOrder={addToOrder}
            changePage={changePage}
            basket={basket}
            products={products}
            removeFromOrder={removeFromOrder}
          />
        );
    }
  };

  return renderPage(props.ui.activePage);
};

export default FreeProducts;
