import styled from 'styled-components';
// import { colors } from './../../assets/variables';

const Wrap = styled.div`
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .tab {
    width: 100%;
    overflow: hidden;
  }
  .tab-label {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
  }

  .tab-content {
    max-height: 60px;
    background: white;
    transition: all 400ms ease-in-out;
    overflow: hidden;
    margin-bottom: 16px;
  }

  input:checked ~ .tab-content {
    max-height: 100vh;
    transition: all 400ms ease-in-out;
  }
`;

export default { Wrap };
