import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const TextWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Img = styled.img`
  height: auto;
  width: 100%;
  margin-right: 0;
  margin-bottom: 24px;

  height: 425px;
  width: auto;
  margin-right: 24px;
  margin-bottom: 0;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`;
const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 8px solid #d7f1ff;
  padding: 24px;
  display: flex;
  flex-direction: row;
  margin-bottom: 96px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: auto;
  }
`;
const OrderBox = styled.div`
  border-radius: 10px;
  background-color: #174489;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex-direction: column;
`;

export default {
  Wrap,
  TextWrap,
  OrderBox,
  Box,
  Img,
};
