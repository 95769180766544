import styled from 'styled-components';

const Wrap = styled.div`
  background: white;
  border-top: 4px solid #007bbf;
  padding: 16px 0;
  margin-top: auto;
  .footer-logo {
    text-align: right;
    @media screen and (max-width: 600px) {
      text-align: left;
      margin-top: 16px;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default { Wrap };
