import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ClientLanding, Landing } from '../../components/Pages';
import { FreeProducts } from '../Pages/FreeProducts';
import { PrivateRoute, DefaultLayout } from '../index';
import * as _ from 'lodash';
import WebFont from 'webfontloader';

const theme = {
  flexboxgrid: {
    outerMargin: 1,
    container: {
      sm: 46,
      md: 61,
      lg: 74,
    },
  },
};

interface AppProps {
  checker: any;
  getWaterClients: Function;
}
const App: React.FC<AppProps> = (props) => {
  const { checker, getWaterClients } = props;

  React.useEffect(() => {
    getWaterClients();
    WebFont.load({
      google: {
        families: ['Open Sans'],
      },
    });
  }, []);

  const data = _.get(checker, 'waterClients.data');
  const dataLength = _.get(data, 'length');
  const singleUrl = _.get(data, '[0].clientSlug');
  const getUrl = `/${singleUrl}`;

  const renderRoutes = () => {
    if (dataLength === 1) {
      return (
        <Route
          path="/"
          element={<Navigate to={data[0].clientSlug} replace />}
        />
      );
    } else {
      return <Route path="/" element={<Landing />} />;
    }
  };
  const renderClientRoutes = () => {
    return _.map(data, (el, index) => {
      const path = `/${el.clientSlug}`;
      return <Route key={index} path={path} element={<ClientLanding />} />;
    });
  };
  const renderPrivateRoute = () => {
    return _.map(data, (el, index) => {
      const path = `/${el.clientSlug}/free-products`;
      return <Route key={index} path={path} element={<FreeProducts />} />;
    });
  };
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          element={
            <PrivateRoute>
              <DefaultLayout />
            </PrivateRoute>
          }
        >
          {renderPrivateRoute()}
        </Route>

        {dataLength === 1 ? (
          <Route path={getUrl} element={<ClientLanding />} />
        ) : (
          renderClientRoutes()
        )}
        {renderRoutes()}
      </Routes>
    </ThemeProvider>
  );
};

export default App;
