import styled, { css } from 'styled-components';

const typographyStyle = css`
  color: #0b2641;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
`;

const HeadOne = styled.h1`
  ${typographyStyle}
  z-index: 10;
  display: inline-block;
  position: relative;
  font-size: 36px;
  @media screen and (max-width: 700px) {
    font-size: 28px;
  }
`;

const HeadTwo = styled.h2`
  ${typographyStyle}
  line-height: 20px;

  @media screen and (max-width: 700px) {
    font-size: 24px;
  }
`;

const HeadThree = styled.h3`
  ${typographyStyle}
  line-height: 20px;

  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
`;
const HeadFour = styled.h4`
  ${typographyStyle}
  line-height: 20px;

  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
`;
const HeadFive = styled.h5`
  ${typographyStyle}
  line-height: 20px;
`;
const HeadSix = styled.h6`
  ${typographyStyle}
  line-height: 20px;
`;
const Paragraph = styled.p`
  ${typographyStyle}
  line-height: 20px;
  font-size: 18px;
`;

export default {
  HeadOne,
  HeadTwo,
  HeadThree,
  HeadFour,
  HeadFive,
  HeadSix,
  Paragraph,
};
