import { combineReducers } from 'redux';
import orderReducer from './order';
import productsReducer from './products';
import userReducer from './user';
import uiReducer from './ui';
import checkerReducer from './checker';
const rootReducer = (state: any, action: any) => {
  return mainReducer(state, action);
};

const mainReducer = combineReducers({
  orderReducer,
  productsReducer,
  checkerReducer,
  userReducer,
  uiReducer,
});

export default rootReducer;
