import React from 'react';
import { Footer, Header, Typography } from '../../index';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Styled from './Landing.style';
import * as _ from 'lodash';

interface LandingProps {
  checker: any;
  getWaterClients: Function;
}

const Landing: React.FC<LandingProps> = (props) => {
  const { checker, getWaterClients } = props;
  const data = _.get(checker, 'waterClients.data');

  React.useEffect(() => {
    getWaterClients();
  }, []);

  const renderBoxes = () => {
    return _.map(data, (el, index) => {
      const url = `https://cdn.getwaterfit.co.uk/static/files/logos/${el.clientSlug}-color.svg`;
      const link = `/${el.clientSlug}`;
      return (
        <Col sm={6} xs={6} md={3} key={index}>
          <Link to={link}>
            <Styled.Box>
              <img src={url} alt="Logo" />
            </Styled.Box>
          </Link>
        </Col>
      );
    });
  };
  return (
    <Styled.Wrapper>
      <Header />
      <Styled.Wrap>
        <Grid>
          <Row>
            <Col md={7} sm={12} xs={12}>
              <Typography size="1" text="Select your water client:" />
              <Row></Row>
            </Col>
          </Row>
          <Row>{renderBoxes()}</Row>
        </Grid>
      </Styled.Wrap>
      <Footer />
    </Styled.Wrapper>
  );
};

export default Landing;
