import * as React from 'react';
import { ProductProps } from '../../interfaces';
import Styled from './Product.style';
import { Icon, Typography } from '../index';

export const Product: React.FC<ProductProps> = (props) => {
  const {
    name,
    featureImage,
    id,
    shortDescription,
    max,
    removeFromOrder,
    addToOrder,
    outOfStock,
    maxInGroup,
    currentItem,
    count,
    onClick,
    limitRule,
  } = props;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const renderButton = () => {
    if (outOfStock) {
      return (
        <p style={{ color: 'red', margin: '13px', fontWeight: 700 }}>
          OUT OF STOCK
        </p>
      );
    }
    return (
      <React.Fragment>
        <Styled.Btn
          data-test="remove"
          colorPrimary="#fff"
          aria-label="Remove item"
          disabled={!count}
          onClick={() => {
            removeFromOrder(id);
          }}
        >
          <div>
            <Icon icon="minus" size={23} />
          </div>
        </Styled.Btn>
        <Styled.CounterText>{count}</Styled.CounterText>
        <Styled.Btn
          data-test="add"
          colorPrimary="#fff"
          aria-label="Add item"
          disabled={
            limitRule === 'mix-and-match'
              ? count >= max || maxInGroup
              : count >= max ||
                (maxInGroup && currentItem && currentItem !== id)
          }
          onClick={() => {
            addToOrder(id);
            onClick();
          }}
        >
          <div>
            <Icon icon="plus" size={23} />
          </div>
        </Styled.Btn>
      </React.Fragment>
    );
  };

  return (
    <Styled.DefaultWrap>
      <Styled.ImageWrap>
        <Styled.Image src={featureImage} alt={name} />
      </Styled.ImageWrap>

      <Styled.Title>
        <Typography size="5" text={name} />
      </Styled.Title>

      <Styled.ShortDescription>
        <Typography
          size="p"
          text={shortDescription}
          style={
            expanded
              ? {
                  maxHeight: '100%',
                  textAlign: 'center',
                  margin: 0,
                  transition: 'max-height 0.2s ease-out',
                }
              : {
                  maxHeight: '60px',
                  overflow: 'hidden',
                  textAlign: 'center',
                  margin: 0,
                  transition: 'max-height 0.2s ease-out',
                }
          }
        />
      </Styled.ShortDescription>
      <p
        style={{
          cursor: 'pointer',
          fontSize: '14px',
          textAlign: 'center',
          fontWeight: 700,
          margin: '6px 0px 16px 0px',
        }}
        onClick={() => handleExpand()}
      >
        Show {!expanded ? 'more' : 'less'}
      </p>

      <Styled.ButtonWrap>{renderButton()}</Styled.ButtonWrap>
    </Styled.DefaultWrap>
  );
};

export default Product;
