import * as React from 'react';

const trash = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(9.500000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="7"
      >
        <line x1="0" y1="18" x2="81" y2="18"></line>
        <path d="M72,18 L72,81 C72,85.5 67.5,90 63,90 L18,90 C13.5,90 9,85.5 9,81 L9,18"></path>
        <path d="M22.5,18 L22.5,9 C22.5,4.5 27,0 31.5,0 L49.5,0 C54,0 58.5,4.5 58.5,9 L58.5,18"></path>
        <line x1="31.5" y1="40.5" x2="31.5" y2="67.5"></line>
        <line x1="49.5" y1="40.5" x2="49.5" y2="67.5"></line>
      </g>
    </g>
  );
};
export default trash;
