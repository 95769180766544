import React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Styled from './Main.style';
import { Outlet } from 'react-router-dom';
export const Main: React.FC = () => {
  return (
    <Styled.Wrap>
      <Grid>
        <Row>
          <Col sm={12} xs={12} md={12}>
            <Outlet />
          </Col>
        </Row>
      </Grid>
    </Styled.Wrap>
  );
};

export default Main;
