import * as React from 'react';

const plus = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        fill={colorPrimary}
        fillRule="evenodd"
        d="M65.25 57l34.58 34.57-8.49 8.49-34.61-34.62a36 36 0 1 1 8.52-8.45zM36 62a26 26 0 1 0 0-52 26 26 0 0 0 0 52z"
      />
    </>
  );
};
export default plus;
