/* eslint-disable indent */
import React from 'react';
import * as _ from 'lodash';
import { Products } from '../../interfaces';
import { Product, Typography, Button } from '../index';
import { Row, Col } from 'react-styled-flexboxgrid';

const ProductList: React.FC<{
  addToOrder: Function;
  changePage: Function;
  basket: {} | undefined;
  products: Products | undefined;
  removeFromOrder: Function;
}> = (props) => {
  const {
    products,
    changePage,
    basket = {},
    addToOrder,
    removeFromOrder,
  } = props;

  const [currentSelect, setSelect] = React.useState(0);
  const singleProduct = (id, groupIndex) => {
    if (products && products.products.data) {
      const elementProps = products.products.data.entities.products[id];

      const {
        limit,
        limitRule,
        inGroup = products.products.groups[groupIndex].products,
      } = products.products.groups[groupIndex];
      const basketIds = Object.keys(basket);
      const count = _.get(basket, `[${id}].total`, 0);

      const productInBasket = _.reduce(
        inGroup,
        (total, id) => {
          if (_.includes(basketIds, id.toString())) {
            return total + 1;
          }
          return total;
        },
        0
      );

      const itemsInBasket = _.reduce(
        inGroup,
        (total, id) => {
          if (_.includes(basketIds, id.toString())) {
            const countItem = _.get(basket, `[${id}].total`, 0);
            return total + countItem;
          }
          return total;
        },
        0
      );

      const getMax =
        limit < inGroup.length
          ? productInBasket === limit
          : productInBasket > limit;

      const maxItemsInGroup = limit == 0 ? false : getMax;
      const maxInGroup = limit > itemsInBasket ? false : true;

      const hasImage = _.get(elementProps, 'images.path', '');
      const productDescription = _.get(elementProps, 'freePackDescription', '');

      const image = hasImage
        ? `https://cdn.savewatersavemoney.co.uk/cache/product_page${hasImage}`
        : 'https://dummyimage.com/600x600/cfcfcf/b3b3b3&text=No+Image';

      return (
        <Col xs={12} sm={4} md={4} style={{ marginBottom: '20px' }} key={id}>
          <Product
            onClick={() => setSelect(id)}
            currentItem={currentSelect}
            addToOrder={addToOrder}
            limitRule={limitRule}
            removeFromOrder={removeFromOrder}
            key={id}
            name={elementProps.title}
            featureImage={image}
            id={id}
            shortDescription={
              productDescription
                ? productDescription
                : 'Save water by challenging yourself to take a shorter shower. Flip this four minute shower timer as a guide to help you gauge the amount of time you spend in the shower. For every minute less you spend in the shower you will use 6 – 15 litres less water.'
            }
            outOfStock={elementProps.state === 'SUSPENDED'}
            max={elementProps.maxperhousehold}
            maxInGroup={
              limitRule === 'mix-and-match' ? maxInGroup : maxItemsInGroup
            }
            count={count}
          />
        </Col>
      );
    }
  };

  const renderProducts = () => {
    if (products && products.products.data) {
      return _.map(products.products.groups, (group, index) => {
        const elements = _.map(group.products, (id) =>
          singleProduct(id, index)
        );

        return (
          <div key={index}>
            <Typography size="2" text={group.name} />
            <Row>{elements}</Row>
          </div>
        );
      });
    }
  };

  return (
    <Row style={{ marginBottom: '32px' }}>
      <Col ms={12} md={12} xs={12}>
        <Typography size="1" text="Free products" />
        <Typography
          size="p"
          text="Please review the free water saving devices below and select those
        suitable for your business. Please help us in our efforts to be more
        sustainable by only ordering what your business needs. Your order will
        be processed and dispatched by Save Water Save Money, on behalf of your
        water retailer and South West Water."
        />
      </Col>
      <Col md={12} sm={12} xs={12}>
        {renderProducts()}
      </Col>
      <Col
        md={12}
        sm={12}
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          disabled={_.size(basket) === 0}
          onClick={() => {
            changePage && changePage('checkout');
          }}
          style={{ height: '50px' }}
        >
          Checkout
        </Button>
      </Col>
    </Row>
  );
};

export default ProductList;
