import * as React from 'react';
import Styled from './Input.style';

interface InputProps {
  type: 'text' | 'number' | 'password' | 'email' | 'tel' | 'date';
  placeholder?: string;
  error?: string;
  label?: string;
  [anyProp: string]: any;
}

export const Input: React.FC<InputProps> = (props) => {
  const { placeholder, error, label, style } = props;

  return (
    <Styled.Wrapper style={style}>
      {label ? <label htmlFor={props.name}>{label}</label> : null}
      <input
        placeholder={placeholder}
        style={{ marginTop: label ? '6px' : '0px' }}
        {...props}
      />
      {error ? <div>{error}</div> : null}
    </Styled.Wrapper>
  );
};

export default Input;
