import * as _ from 'lodash';
import { Products } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import store from 'store2';
export const initialState: Products = {
  products: {
    status: {
      loading: false,
      error: null,
      done: false,
    },
    data: null,
    basket: {},
    groups: [],
  },
  addressCheck: {
    status: {
      loading: false,
      error: null,
      done: false,
    },
  },
  creditAudit: [],
  basketRejected: false,
};

const productsReducer = createReducer(initialState, {
  RESET: (state) => {
    state.products.basket = {};
  },
  ADDRESS_CHECK_PENDING: (state) => {
    state.addressCheck.status = {
      loading: true,
      error: null,
      done: false,
    };
  },
  ADDRESS_CHECK_FULFILLED: (state, action) => {
    state.addressCheck.status = {
      loading: false,
      error: null,
      done: true,
    };
    state.creditAudit = _.get(action, 'payload.data.creditAudit', []);
    state.basketRejected = _.get(action, 'payload.data.basket.rejected', false);
  },
  ADDRESS_CHECK_REJECTED: (state, action) => {
    state.addressCheck.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.creditAudit = _.get(action, 'payload.response.data.creditAudit', []);
    state.basketRejected = _.get(
      action,
      'payload.response.data.basket.rejected',
      false
    );
  },
  CLEAR_ADDRESS: (state) => {
    state.creditAudit = [];
    state.basketRejected = false;
  },
  GET_CLIENT_FULFILLED: (state, action) => {
    const groups: any = [];
    _.forEach(
      _.get(action, 'payload.data.data.items[0]groups', []),
      (group) => {
        const { name, limit, products, limitRule } = group;
        groups.push({
          name,
          limit,
          limitRule,
          products: _.map(products, (product) => product.id),
        });
      }
    );
    const productsEntity = new schema.Entity('products');
    const appSchema = new schema.Array(productsEntity);
    const allProducts: any = [];
    _.forEach(
      _.get(action, 'payload.data.data.items[0]groups', []),
      (group) => {
        allProducts.push(...group.products);
      }
    );
    const normalizedData: any = normalize(allProducts, appSchema);

    state.products.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.products.data = normalizedData;
    state.products.groups = groups;
  },
  GET_CLIENT_PENDING: (state) => {
    state.products.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_CLIENT_REJECTED: (state, action) => {
    state.products.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  ADD_TO_ORDER: (state, action) => {
    state.products.basket[action.id] = {
      total: _.get(state, `products.basket.${action.id}.total`, 0) + 1,
    };
  },
  REMOVE_FROM_ORDER: (state, action) => {
    const currentQuantity = _.get(state, `products.basket.${action.id}.total`);

    if (currentQuantity > 1) {
      state.products.basket[action.id].total = currentQuantity - 1;
    } else {
      delete state.products.basket[action.id];
    }
  },
  DELETE_FROM_ORDER: (state, action) => {
    store.remove(`${[action.id]}`);
    delete state.products.basket[action.id];
  },
  UPDATE_ORDER: (state, action) => {
    const allowedQuantity = _.get(
      state,
      `creditAudit.${action.id}.allowedQuantity`
    );
    const currentQuantity = _.get(state, `products.basket.${action.id}.total`);
    if (state.products.basket[action.id].total > allowedQuantity) {
      state.products.basket[action.id].total =
        currentQuantity - (currentQuantity - allowedQuantity);
    }
  },
});

export default productsReducer;
