import axios from 'axios';
import store from 'store2';
import * as _ from 'lodash';

export function getVariable(scope: string, name: string, fallback: any = '') {
  return _.get(window, `swsm.${scope}.${name}`, fallback);
}

export const isLoggedIn = () => {
  const userFromLocalStorage = store('user');

  return userFromLocalStorage;
};
export function getUrl() {
  return window.location.host;
}
export function getV8MicroServiceUrl(name: string) {
  const env = getUrl();
  if (
    env === 'localhost:3000' ||
    env === 'dev.business.savewatersavemoney.co.uk'
  ) {
    return `https://api.dev.business.savewatersavemoney.co.uk/${name}/api/1.0/`;
  } else if (env === 'stage.business.savewatersavemoney.co.uk') {
    return `https://api.stage.business.savewatersavemoney.co.uk/${name}/api/1.0/`;
  }
  return `https://api.savewatersavemoney.co.uk/${name}/api/1.0/`;
}

export function setupInterceptors() {
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response;
      if (status === 401) {
        localStorage.removeItem('swsmUser');
        localStorage.removeItem('user');
        localStorage.removeItem('metadata');
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
}
