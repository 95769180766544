import styled from 'styled-components';

const InputSearch = styled.input`
  max-height: 50px;
  width: 100%;
  padding: 0 16px;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 10px 0px 0px 10px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;

  #search-postcode {
    height: 50px;
    border-radius: 0px 10px 10px 0px;
  }
`;

export default { InputSearch, InputGroup };
