/* eslint-disable no-case-declarations */
import * as _ from 'lodash';
import { normalize, schema } from 'normalizr';
import { User } from '../interfaces';

const initialState: User = {
  submission: {
    status: {
      loading: false,
      error: null,
      done: false,
    },
    data: null,
  },
  addresses: {
    status: {
      loading: false,
      error: null,
      done: false,
    },
    data: null,
  },

  details: {
    email: '',
    title: '',
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
    toc: false,
    spid: '',
    numberOfStaff: '',
    numberOfVisitors: '',
    operatingDays: '',
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case 'GET_ADDRESSES_PENDING':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          status: {
            loading: true,
            error: null,
            done: false,
          },
        },
      };
    case 'GET_ADDRESSES_WRONG_PROVIDER':
      return {
        ...state,
        addresses: {
          data: null,
          status: {
            loading: false,
            error: action.payload,
            done: true,
          },
        },
      };
    case 'GET_ADDRESSES_FULFILLED':
      const addressEntity = new schema.Entity('addresses');
      const appSchema = new schema.Array(addressEntity);
      const normalizedData: any = normalize(
        action.payload.data.data.items,
        appSchema
      );

      return {
        ...state,
        addresses: {
          ...state.addresses,
          status: {
            loading: false,
            error: null,
            done: true,
          },
          data: normalizedData,
        },
      };
    case 'GET_ADDRESSES_REJECTED':
      console.log(action.payload.response);
      return {
        ...state,
        addresses: {
          status: {
            loading: false,
            error: action.payload.response.data.errors,
            done: true,
          },
          data: null,
        },
      };

    case 'CLEAR_ADDRESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          status: {
            loading: false,
            error: null,
            done: false,
          },
          data: null,
        },
        details: {
          ...state.details,
          address: '',
          postcode: '',
        },
      };

    case 'PERSIST_DETAILS':
      return {
        ...state,
        details: action.details,
      };

    case 'SUBMIT_ORDER_PENDING':
      return {
        ...state,
        submission: {
          status: {
            loading: true,
            error: false,
            done: false,
          },
        },
      };

    case 'SUBMIT_ORDER_FULFILLED':
      return {
        ...state,
        submission: {
          status: {
            loading: true,
            error: false,
            done: false,
          },
          data: _.get(action, 'payload.data'),
        },
      };

    case 'SUBMIT_ORDER_REJECTED':
      return {
        ...state,
        submission: {
          status: {
            loading: false,
            error: true,
            done: false,
          },
          data: action.payload.response.data.errors,
        },
        creditAudit: action.payload.response.data.creditAudit,
        basketRejected: action.payload.response.data.basketRejected,
      };

    default:
      return state;
  }
};

export default userReducer;
