import * as React from 'react';
import Styled from './Button.style';

export interface ButtonProps {
  full?: boolean;
  style?: Object;
  disabled?: boolean;
  id?: string;
  onClick?: (e: any) => void;
  title?: string;
  type?: 'button' | 'submit';
  className?: string;
  theme?: 'COLOR' | 'WHITE';
  children: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = (props) => {
  return <Styled.Button {...props}>{props.children}</Styled.Button>;
};
export default Button;
