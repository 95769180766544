import { connect } from 'react-redux';
import { AppState } from '../../interfaces';
import { getWaterClients } from '../../actions/checker';
import App from './App';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    checker: state.checkerReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWaterClients: (data) => dispatch(getWaterClients(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
