import React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Styled from './Header.style';
import { useLocation, useNavigate } from 'react-router-dom';

interface HeaderProps {
  reset: Function;
}

export const Header: React.FC<HeaderProps> = (props) => {
  const { reset } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.substring(1);
  const clientSlug = location.pathname.split('/');

  const clientLogo = clientSlug[1]
    ? `https://cdn.getwaterfit.co.uk/static/files/logos/${clientSlug[1]}-color.svg`
    : `https://cdn.getwaterfit.co.uk/static/files/logos/${path}-color.svg`;
  const swsmLogo =
    'https://cdn.getwaterfit.co.uk/static/files/logos/swsm-color.svg';
  return (
    <Styled.Wrap>
      <Grid>
        <Row>
          <Col md={12}>
            <img
              onClick={() => {
                if (path === 'southwest') {
                  return void 0;
                } else {
                  localStorage.removeItem('user');
                  navigate('/');
                  reset();
                }
              }}
              src={
                path === '' || path.includes('landing') ? swsmLogo : clientLogo
              }
              alt={path}
              style={
                path === '' || path.includes('landing')
                  ? {
                      maxHeight: '48px',
                      height: '48px',
                      padding: '16px 0',
                      cursor: 'pointer',
                    }
                  : {
                      maxHeight: '56px',
                      height: '56px',
                      padding: '12px 0',
                      cursor: 'pointer',
                    }
              }
            />
          </Col>
        </Row>
      </Grid>
    </Styled.Wrap>
  );
};

export default Header;
