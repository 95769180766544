import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Wrap = styled.div`
  background: #d7f1ff;
  padding: 60px;
  flex: 1;
  @media screen and (max-width: 600px) {
    padding: 0px;
  }
`;
const Box = styled.div`
  border-radius: 6px;
  border: 4px solid #007bbf;
  background: white;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
  &:hover {
    scale: 1.1;
    transition: all 200ms ease-in-out;
  }
  @media screen and (max-width: 600px) {
    img {
      width: 150px;
    }
  }
`;
export default { Wrapper, Wrap, Box };
